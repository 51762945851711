
.tableBody {
    display: block;
    height: 500px;
    overflow-x: hidden;
}
.tableHead, .tableBody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    
}
.tableHead {
    width: 100%
}
/* .customTable {
    width: 400px;
} */