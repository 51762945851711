.login-mt{
    margin-top: 9% !important;
}

.login-head{
    margin-top: 8% !important;
}

.login-color{
    background-color:#005691 !important;
}

.login-btn-color{
    background-color: #005691 !important;
    color:#fff;
}

.txt-color{
    color:#005691 !important;
}

.editprof-save-btn{
    margin-right:30px;
}

.sidebar-bg-color{
    background-color: #005691 !important;
}

.sidebar-menu-font .active{
    color:#fff;
}

.logo-sidebar-name{
    color:#000;
    font-size: 20px;
}

//css for width of search and dropdown of Account page
.sw > label{
    width:100%
}

.search-w{
    width: 100%;    //searchbox width
}
.search-p{
    padding-right:0%    //searchbox padding
}
.dropdown-w{
    width: 100%;    //dropdown-width
}
.dropdown-p{
    padding-left: 0%;   //dropdown-padding
}
.drpdwn{    
    width: 70px;
    background-color: #005691;
    color: #fff;
    border-radius: 4px;
}
.dropdown-option{
    background-color: #fff !important;
    &:active{
    background-color: #005691 !important;
    }
}

.drpOpen{
    display: block !important;
}

.radio-btn-dashboard{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.radio-btn-p{
    padding-right: 20px;
}

.client{
    display: inline-block;
    flex-direction: column;
    margin: auto 10px;
}

.client1{
    margin: auto 5px;
}
.cl-p{
    padding-bottom: 14px;
}

.myclient{
    margin-bottom: 20px;
}

.gridbtn-m{
    margin-right: 5px;
    border-radius:20px;
    // color:#005691;
}

.card-bg{
    background-color: #edf0f5
}

select.arrows {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - .5rem), 100% 0;
    background-size:  0.7em 0.7em;
    background-repeat: no-repeat;
}

.mr-4{
    margin-right: 50px !important;
}

.modal-footer{
    padding-right: 0 !important;
}

.arrow-spacing{
    margin-right:10px !important;
}

.number{
    margin: 0 !important;
    width: 50px !important;
}

.number2{
    margin: 0 !important;
    width: 38px !important;
}

.nav-link:active{
    color: #005691;
}

.row-top-3{
    margin-top: 5opx;
}


.wizard .steps .number{
    color: #005691 !important;
    border-color: #005691 !important;
    width: 38px !important;
}

.wizard .steps .current a .number{
    color: #fff !important;
    background-color: #005691 !important;
    border-color: #005691 !important;
}
.bg-theme-color{
    background-color: #005691 !important;
    color:#fff !important;
}

.disclosure-price-float-right{
    float: right;
}

.journal-td{
    width:150px !important
}

.nav-tabs-custom .nav-item .nav-link.active{
    color:#005691
}
.nav-tabs-custom .nav-item .nav-link.active::after{
    background:#005691
}
.nav-tabs-custom .nav-item .nav-link:hover{
    color:#005691
}

.text-align-left{
    text-align: left;
}

.sidebar-link span{
    color: #fff !important;
}

.sidebar-link i{
    color: #fff !important;
}

.sidebar-link.active span{
    text-decoration: underline !important;
}

.sidebar-link:hover{
    background-color: #005691 !important;
}

.image-area {
    position: relative;
    width: 100px;
    height: 100px;
    background: #333;
}

.image-area img{
    width: 100px;
    height: 100px;
}

.remove-image {
    position: absolute;
    color: red;
    font-size: 20px;
    top: -20px;
    right: -20px;
    cursor: pointer;
}

.avatar-title{
    font-size: 24px !important;
    text-transform: uppercase !important;
}

.title{
    font-size: 24px !important;
    text-transform: uppercase !important;
    padding: 0;
}

.react-bs-table-no-data{
    text-align: center !important;
}

.table-height{
    min-height: 300px !important;
}

.table-height_je{
    min-height: 500px !important;
}

.table-data-margin{
    margin-top: 14px;
}

.font-italic-bold{
    color: black;
    font-weight: bold;
    font-size: 15px;
}

.alert-text{
    font-weight: bold; 
    font-size: 17px;
}

.table-max-height{
    max-height: 400px !important;
}

.table-max-height_je{
    max-height: 600px !important;
}

.table-lease-max-height{
    max-height: 620px !important;
}

.fixed-width-column{
    width: 170px;
    white-space: pre-wrap;
}

.sticky-header{
    position: sticky;
    top: 0;
    z-index: 2;
}

.sticky-footer{
    position: sticky;
    bottom: 0;
    background-color: #edf0f5;
}

.client-name{
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 100px;
}

.lease-name{
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 400px;
}

.lease-column-width{
    min-width: 150px;
}

.flatpickr-monthSelect-month.selected{
    background-color: #005691;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cursor-not-allowed:hover{
    cursor: not-allowed !important;
}

.btn-xs{
    padding: 0rem 0.25rem !important;
    font-size: 0.71094rem !important;
    border-radius: 0.2rem !important;
}

.btn-transparent{
    background: transparent !important;
    color: #000 !important;
    border: none !important;
    font-size: 1rem !important;
}

.react-toast-notifications__container{
    z-index: 9999 !important;
}

.rollforward_table th{
  border: 2px solid rgb(221, 221, 226) !important;
  min-width: 200px;
}

.rollforward_table thead{
    background-color: #f8f9fa;
}

.glgroup_table{
    border: 1px solid #c3c1c185 !important;
    margin-top: 50px !important;
}

.glgroup_table td{
    border: 1px solid #c3c1c185 !important;
}

.glgroup_table th{
    border: 2px solid #c3c1c185 !important;
}

.glgroup_seperator{
    padding: 0;
    height: 2rem;
}

.glgroup_table thead th {
    position: sticky;
    top: 0;
    z-index: 6666;
}

.glgroup_table thead tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 8888;    
    border: 2px solid #c3c1c185 !important;
}

.glgroup_table tbody tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 6666;
    background-color: #f8f9fa;
    border: 1px solid #c3c1c185 !important;
}

.glgroup_table thead tr th:nth-child(2) {
    position: sticky;
    left: 375px;
    z-index: 8888;    
    border: 2px solid #c3c1c185 !important;
}

.glgroup_table tbody tr td:nth-child(2) {
    position: sticky;
    left: 375px;
    z-index: 6666;
    background-color: #f8f9fa;
    border: 1px solid #c3c1c185 !important;
}

.glgroup_table tbody td {
    z-index: 5555;
}

.glgroup_table-max-height{
    max-height: 60vh !important;
}

.glmappingJE_table-max-height{
    max-height: 50vh !important;
}

.MuiAutocomplete-inputRoot{
    padding: 0 !important;
}

.z-index-1{
    z-index: 1;
}

.collapse-payment{
    border: 1px solid #00000020;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.collapse-payment h2 button{
    cursor: pointer !important; 
    color: #005691 !important;
    padding:15px;
}

.table > :not(caption) > * > * {
    background-color: transparent !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background-color: transparent !important;
}

.lease-listing-table div{
    padding: 0;
}